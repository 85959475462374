import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby";

/**
 * Components
 */
import FilterForm from '@blocks/forms/filter-form';
import PropertiesList from '@components/properties-list/properties-list';

/**
 * Assets
 */

 import './styles/_index.scss'

const Results = () => {
    const [result, setResult] = useState({
        type: "buy",
        search: "",
        min_price: "",
        max_price: "",
        bedrooms: "",
        radius: ""
    });
    const data = useStaticQuery(graphql`
        query {
        avatar1: file(relativePath: { eq: "avatar1.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        property1: file(relativePath: { eq: "property1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 763) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        property2: file(relativePath: { eq: "property2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 763) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        property3: file(relativePath: { eq: "property3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 763) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        property4: file(relativePath: { eq: "property4.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 763) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        property5: file(relativePath: { eq: "property5.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 763) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        property6: file(relativePath: { eq: "property6.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 763) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        property7: file(relativePath: { eq: "property7.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 763) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        property8: file(relativePath: { eq: "property8.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 763) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);
    const properties = [
        {
            title: "Lipton Close, Southgate, N14",
            type: "buy",
            price: "£1,800,000",
            bedrooms: "4 bedroom detached house for sale",
            content: "One of only three exclusive townhouses set within five acres of private beautifully manicured grounds with on-site security and parking.",
            bedrooms_int: 4,
            price_int: 1800000,
            lat: 51.644620,
            lng: -0.130030,
            images: [data.property1.childImageSharp.fluid, data.property1.childImageSharp.fluid, data.property1.childImageSharp.fluid],
            author: {
                avatar: data.avatar1.childImageSharp.fluid,
                name: "Stephanie Stavrou",
                phone: "0208  882 6568",
                video_call: "#"
            }
        },
        {
            title: "Eaststand Apartments, Highbury Stadium Square, London, N5",
            type: "sell",
            price: "£1,750,000 Guide Price",
            bedrooms: "2 bedroom apartment for sale",
            content: "One of only three exclusive townhouses set within five acres of private beautifully manicured grounds with on-site security and parking.",
            bedrooms_int: 2,
            price_int: 1750000,
            lat: 51.558190,
            lng: -0.104320,
            images: [data.property2.childImageSharp.fluid, data.property2.childImageSharp.fluid, data.property2.childImageSharp.fluid],
            author: {
                avatar: data.avatar1.childImageSharp.fluid,
                name: "Loui Panayiotou",
                phone: "0208 192 0293",
                video_call: "#"
            }
        },
        {
            title: "Eaststand Apartments, Highbury Stadium Square, London, N5",
            type: "buy",
            price: "£1,750,000 Guide Price",
            bedrooms: "4 bedroom apartment for sale",
            content: "One of only three exclusive townhouses set within five acres of private beautifully manicured grounds with on-site security and parking.",
            bedrooms_int: 4,
            price_int: 1750000,
            lat: 51.570740,
            lng: -0.114490,
            images: [data.property3.childImageSharp.fluid, data.property3.childImageSharp.fluid, data.property3.childImageSharp.fluid],
            author: {
                avatar: data.avatar1.childImageSharp.fluid,
                name: "Loui Panayiotou",
                phone: "0208 192 0293",
                video_call: "#"
            }
        },
        {
            title: "Eaststand Apartments, Highbury Stadium Square, London, N5",
            type: "buy",
            price: "£1,750,000 Guide Price",
            bedrooms: "1 bedroom apartment for sale",
            content: "One of only three exclusive townhouses set within five acres of private beautifully manicured grounds with on-site security and parking.",
            bedrooms_int: 1,
            price_int: 1750000,
            lat: 51.644619,
            lng: -0.130030,
            images: [data.property4.childImageSharp.fluid, data.property4.childImageSharp.fluid, data.property4.childImageSharp.fluid],
            author: {
                avatar: data.avatar1.childImageSharp.fluid,
                name: "Loui Panayiotou",
                phone: "0208 192 0293",
                video_call: "#"
            }
        },
        {
            title: "Eaststand Apartments, Highbury Stadium Square, London, N5",
            type: "sell",
            price: "£1,750,000 Guide Price",
            bedrooms: "4 bedroom apartment for sale",
            content: "One of only three exclusive townhouses set within five acres of private beautifully manicured grounds with on-site security and parking.",
            bedrooms_int: 4,
            price_int: 1750000,
            lat: 51.632620,
            lng: -0.187000,
            images: [data.property5.childImageSharp.fluid, data.property5.childImageSharp.fluid, data.property5.childImageSharp.fluid],
            author: {
                avatar: data.avatar1.childImageSharp.fluid,
                name: "Loui Panayiotou",
                phone: "0208 192 0293",
                video_call: "#"
            }
        },
        {
            title: "Eaststand Apartments, Highbury Stadium Square, London, N5",
            type: "buy",
            price: "£2,750,000 Guide Price",
            bedrooms: "2 bedroom apartment for sale",
            content: "One of only three exclusive townhouses set within five acres of private beautifully manicured grounds with on-site security and parking.",
            bedrooms_int: 2,
            price_int: 2750000,
            lat: 51.432620,
            lng: -0.287000,
            images: [data.property6.childImageSharp.fluid, data.property6.childImageSharp.fluid, data.property6.childImageSharp.fluid],
            author: {
                avatar: data.avatar1.childImageSharp.fluid,
                name: "Loui Panayiotou",
                phone: "0208 192 0293",
                video_call: "#"
            }
        },
        {
            title: "Eaststand Apartments, Highbury Stadium Square, London, N5",
            type: "buy",
            price: "£2,550,000 Guide Price",
            bedrooms: "4 bedroom apartment for sale",
            content: "One of only three exclusive townhouses set within five acres of private beautifully manicured grounds with on-site security and parking.",
            bedrooms_int: 4,
            price_int: 2550000,
            lat: 51.632620,
            lng: -0.387000,
            images: [data.property7.childImageSharp.fluid, data.property7.childImageSharp.fluid, data.property7.childImageSharp.fluid],
            author: {
                avatar: data.avatar1.childImageSharp.fluid,
                name: "Loui Panayiotou",
                phone: "0208 192 0293",
                video_call: "#"
            }
        },
        {
            title: "Eaststand Apartments, Highbury Stadium Square, London, N5",
            type: "buy",
            price: "£3,750,000 Guide Price",
            bedrooms: "3 bedroom apartment for sale",
            content: "One of only three exclusive townhouses set within five acres of private beautifully manicured grounds with on-site security and parking.",
            bedrooms_int: 3,
            price_int: 3750000,
            lat: 51.232620,
            lng: -0.987000,
            images: [data.property8.childImageSharp.fluid, data.property8.childImageSharp.fluid, data.property8.childImageSharp.fluid],
            author: {
                avatar: data.avatar1.childImageSharp.fluid,
                name: "Loui Panayiotou",
                phone: "0208 192 0293",
                video_call: "#"
            }
        }
    ];
    const propertiesFiltered = properties.filter(property => {
        if(
            property.type === result.type &&
            (parseInt(property.price_int) >= parseInt(result.min_price) || result.min_price === '') &&
            (parseInt(property.price_int) <= parseInt(result.max_price) || result.max_price === '') &&
            (parseInt(property.bedrooms_int) === parseInt(result.bedrooms) || result.bedrooms === '')
        ){
            return property;
        }
    })
    return (
        <div className="results">
            <FilterForm data={result} change={setResult} />
            <PropertiesList properties={propertiesFiltered} />
        </div>
    )
}

export default Results
