import React, {useState} from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import Select from 'react-select';

/**
 * Components
 */
import useDeviceDetect from '@blocks/detect-device/detect-device';
import { SmallSearch, PlusDark, Minus } from '@components/icon/icon'

const FilterForm = ({data, change}) => {
    const { isMobile } = useDeviceDetect();
    const [filterText, setFilterText] = useState('More Filters')
    const styles = {
        control: styles => ({ 
            ...styles, 
            backgroundColor: null, 
            border: 0,
            paddingLeft: 0,
            paddingBottom: isMobile ? '0' : '1.1rem',
            borderBottom: '1px solid #000',
            borderRadius: 0,
            outline: 0,
            fontSize: () => isMobile ? '1.8rem' : '2.2rem',
            boxShadow: 'none',
            color: '#4A4A4A',
        }),
        valueContainer: (styles) => ({
            ...styles,
            fontSize: () => isMobile ? '1.8rem' : '2.2rem',
            paddingLeft: 0
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "#5D9272"
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            color: '#5D9272',
        }),
        indicatorSeparator: () => null,
        placeholder: (styles) => ({
            ...styles,
            marginLeft: 0
        })
    }

    const handelToggle = () => {
        setFilterText(text => text === "More Filters" ? "Hide Filters" : 'More Filters')
    }

    const DownIndicator = () => (
        <div className="down-indicator">
            <PlusDark />
        </div>
    )

    return (
        <div className="filter-form">
            <Container>
                <form>
                    <Row>
                        <Col className="d-none d-md-block">
                            <Select 
                                options={[
                                    { value: 'buy', label: 'Buy' },
                                    { value: 'sell', label: 'Sell' }
                                ]}
                                styles={styles}
                                placeholder="Buy"
                                defaultValue={data.type}
                                onChange={(e) => change({
                                    ...data,
                                    type: e.value
                                })}
                                components={{ DropdownIndicator: () => <DownIndicator /> }}
                            />
                        </Col>
                        <Col>
                            <div className="field-row">
                                <SmallSearch />
                                <input 
                                    type="text" 
                                    name="search"
                                    className="search-field"
                                    value={data.search} 
                                    placeholder="Street, area or postcode" 
                                    onChange={(e) => change({
                                    ...data,
                                    search: e.target.value
                                })} />
                                <span className="d-none d-md-block">+ 2 Miles</span>
                            </div>
                        </Col>
                        <Col className="d-none d-lg-block">
                            <Select 
                                options={[
                                    { value: '1000000', label: '1000000' },
                                    { value: '2000000', label: '2000000' },
                                    { value: '3000000', label: '3000000' },
                                    { value: '4000000', label: '4000000' },
                                ]} 
                                styles={styles}
                                placeholder="Min Price"
                                defaultValue={data.min_price}
                                onChange={(e) => change({
                                    ...data,
                                    min_price: e.value
                                })}
                                components={{ DropdownIndicator: () => <DownIndicator /> }}
                            />
                        </Col>
                        <Col className="d-none d-lg-block">
                            <Select 
                                options={[
                                    { value: '1000000', label: '1000000' },
                                    { value: '2000000', label: '2000000' },
                                    { value: '3000000', label: '3000000' },
                                    { value: '4000000', label: '4000000' },
                                ]}  
                                styles={styles}
                                placeholder="Max Price"
                                defaultValue={data.max_price}
                                onChange={(e) => change({
                                    ...data,
                                    max_price: e.value
                                })}
                                components={{ DropdownIndicator: () => <DownIndicator /> }}
                            />
                        </Col>
                        <Col className="d-none d-lg-block">
                            <Select 
                                options={[
                                    { value: '1', label: '1' },
                                    { value: '2', label: '2' },
                                    { value: '3', label: '3' },
                                    { value: '4', label: '4' },
                                ]} 
                                styles={styles}
                                placeholder="Bedrooms"
                                defaultValue={data.bedrooms}
                                onChange={(e) => change({
                                    ...data,
                                    bedrooms: e.value
                                })}
                                components={{ DropdownIndicator: () => <DownIndicator /> }}
                            />
                        </Col>
                    </Row>
                    <Accordion className="d-lg-none">
                        <Accordion.Toggle variant="link" eventKey="1" onClick={handelToggle}>{filterText} <span>{filterText === "More Filters" ? <PlusDark /> : <Minus />}</span></Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Row>
                                <Col xs="12" md="4" className="d-md-none">
                                    <Select 
                                        options={[
                                            { value: 'buy', label: 'Buy' },
                                            { value: 'sell', label: 'Sell' }
                                        ]}
                                        styles={styles}
                                        placeholder="Buy"
                                        defaultValue={data.type}
                                        onChange={(e) => change({
                                            ...data,
                                            type: e.value
                                        })}
                                        components={{ DropdownIndicator: () => <DownIndicator /> }}
                                    />
                                </Col>
                                <Col xs="12" md="4">
                                    <Select 
                                        options={[
                                            { value: '1000000', label: '1000000' },
                                            { value: '2000000', label: '2000000' },
                                            { value: '3000000', label: '3000000' },
                                            { value: '4000000', label: '4000000' },
                                        ]} 
                                        styles={styles}
                                        placeholder="Min Price"
                                        defaultValue={data.min_price}
                                        onChange={(e) => change({
                                            ...data,
                                            min_price: e.value
                                        })}
                                        components={{ DropdownIndicator: () => <DownIndicator /> }}
                                    />
                                </Col>
                                <Col xs="12" className="d-md-none">
                                    <Select 
                                        options={[
                                            { value: '1000000', label: '1000000' },
                                            { value: '2000000', label: '2000000' },
                                            { value: '3000000', label: '3000000' },
                                            { value: '4000000', label: '4000000' },
                                        ]} 
                                        styles={styles}
                                        placeholder="Location Radius"
                                        defaultValue={data.radius}
                                        onChange={(e) => change({
                                            ...data,
                                            radius: e.value
                                        })}
                                        components={{ DropdownIndicator: () => <DownIndicator /> }}
                                    />
                                </Col>
                                <Col xs="12" md="4">
                                    <Select 
                                        options={[
                                            { value: '1000', label: '1000' },
                                            { value: '2000', label: '2000' }
                                        ]} 
                                        styles={styles}
                                        placeholder="Max Price"
                                        defaultValue={data.max_price}
                                        onChange={(e) => change({
                                            ...data,
                                            max_price: e.value
                                        })}
                                        components={{ DropdownIndicator: () => <DownIndicator /> }}
                                    />
                                </Col>
                                <Col xs="12" md="4">
                                    <Select 
                                        options={[
                                            { value: '1', label: '1' },
                                            { value: '2', label: '2' },
                                            { value: '3', label: '3' },
                                            { value: '4', label: '4' },
                                        ]} 
                                        styles={styles}
                                        placeholder="Bedrooms"
                                        defaultValue={data.bedrooms}
                                        onChange={(e) => change({
                                            ...data,
                                            bedrooms: e.value
                                        })}
                                        components={{ DropdownIndicator: () => <DownIndicator /> }}
                                    />
                                </Col>
                            </Row>
                        </Accordion.Collapse>
                    </Accordion>
                </form>
            </Container>
        </div>
    )
}

export default FilterForm
