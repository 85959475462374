import React from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";

import "aos/dist/aos.css";

import Results from '@components/results/results';
import Valuation from "@components/valuation/valuation";

const Contact = () => (
    <Layout>
      <SEO title="Contact" />
      <Results />
      <Valuation />
    </Layout>
)

export default Contact