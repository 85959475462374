import React from 'react'
import Slider from 'react-slick';
import Img from 'gatsby-image'
import { Col, Row } from 'react-bootstrap';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import ContactBox from '@components/contact-box/contact-box';
import { Heart } from '@components/icon/icon';

const PropertyBoxRow = ({property}) => {
    const settings = {
        dots: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className="property-box-row">
            <Row>
                <Col md="12" lg="7">
                    <div className="property-slider">
                        <Slider {...settings}>
                            {property.images.map((image, index) =>(
                                <div key={index}>
                                    <Img fluid={image} />
                                </div>
                            ))}
                        </Slider>
                        <a href="/" className="heart">
                            <Heart />
                        </a>
                    </div>
                </Col>
                <Col md="12" lg="5">
                    <div className="property-content d-flex flex-wrap">
                        <div className="top">
                            <h4>{property.title}</h4>
                            <div className="price">{property.price}</div>
                            <div className="bedrooms">{property.bedrooms}</div>
                            <div className="divider d-none d-lg-block"></div>
                            <div className="content d-md-none d-lg-block">
                                <p>{property.content}</p>
                            </div>
                        </div>
                        <div className="bottom mt-auto d-none d-md-block">
                            <ContactBox name={property.author.name} phone={property.author.phone} link={property.author.video_call} linkText="Book a Video Call" image={property.author.avatar} />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PropertyBoxRow
